


























import { Component, Watch } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';
import { Company, CompanySearchRequest, PagedResult } from '@shared/models';
import CompanyItem from '@client/views/industry/companies/CompanyItem.vue';

@Component({
	components: {
		CompanyItem,
	},
})
export default class ArtistCompanies extends BaseView {


	public modelId: string;
	public list: PagedResult<Company> = new PagedResult<Company>();
	public criteria: CompanySearchRequest = new CompanySearchRequest({ pageIndex: 0, pageSize: 32, sortBy: 'year', sortDirection: 'asc' });

	public async mounted() {
		await this.refresh();
	}

	@Watch('$route', { immediate: true, deep: true })
	public async refresh() {
		this.loading = true;
		try {
			this.criteria = new CompanySearchRequest(this.$route.query);
			this.modelId = this.$route.params.id;
			this.criteria.artistIds = [ this.modelId ];
			this.list = await this.$api.companyService.search(this.criteria);
		} catch (err) {
			this.errorHandler.handle(err, 'An error occurred while loading this company associations for this artist');
		} finally {
			this.loading = false;
		}
	}

	/** Fires when the page is changed */
	public async pageChanged(page: number) {
		this.criteria.pageIndex = (page - 1);
		await this.filter(false);
	}

	/** Applies the specified filter and redirects to the new page with the correct query parameters */
	public async filter(resetPage: boolean) {
		this.scroll.scrollToTop();
		if (resetPage) { this.criteria.pageIndex = 0; }
		this.$router.push({ name: 'artist-companies', params: { id: this.modelId }, query: this.criteria.collapse() }).catch(() => { /* INTENTIONALLY EMPTY */ });
		await this.refresh();
	}



}

